import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import LinkSwitch from './link_switch'
import TGRDetailsPointer from './details_pointer'

import * as styles from './feature_banner.module.scss'

import PropTypes from 'prop-types'

export default function TGRFeatureBanner ({ item, stylingResolver, slugCreater }) {
  
  const lbl = item.readMeLabel !== null && item.readMeLabel.trim().length > 0 ? item.readMeLabel.trim().toUpperCase() : 'DETAILS'
  const internalSlug =
  item.targetPageId !== null ? slugCreater(item.targetPageId) : null
  || item.targetAsset1 !== null ? item.targetAsset1.publicURL : null
  const externalSlug = item.targetUrl
  const isInternal = internalSlug !== null
  const newWindow = item.winTarget !== undefined && item.winTarget !== null ? item.winTarget : !isInternal
  const isValid = internalSlug !== null || externalSlug !== null

  const imageSizeCapitalized = item.bgImageHeight.charAt(0).toUpperCase() + item.bgImageHeight.slice(1)
  const headlineSizeCapitalized = item.headlineFontSize.charAt(0).toUpperCase() + item.headlineFontSize.slice(1)

  const aspectRatio = styles[`aspectRatio${imageSizeCapitalized}`]
  const headlineSizeStyle = styles[`size${headlineSizeCapitalized}`]

  const headline = item.headline !== null ? item.headline.toUpperCase() : undefined
  const edging = item.headlineEdging === null || item.headlineEdging === 'edged' ? 'Edge' : ''
  const headlineStyle = `${item.headlinePosition === null || item.headlinePosition !== 'top' ? styles.bottom : styles.top}`
    + ` ${item.headlineColour === null || item.headlineColour === 'black' ? styles['black'+edging] : styles['white'+edging]}`

  const bgImg = getImage(item.bgImg)  

  return (
    <div className={styles.containerWrapper}>
      <div className={aspectRatio}></div>
      <div className={styles.content}>
        <GatsbyImage className={styles.item} layout="fullWidth" alt="" image={bgImg} formats={["auto", "webp", "avif"]} />
        {headline !== undefined && <div className={styles.headerLine + ' ' + headlineStyle}><h1 className={headlineSizeStyle}>{headline}</h1></div>}
        {isValid === true && <div className={styles.slugLine}><LinkSwitch isInternal={isInternal} url={isInternal ? internalSlug : externalSlug} newWindow={newWindow}><TGRDetailsPointer label={lbl} style={stylingResolver(item)}/></LinkSwitch></div>}
      </div>
    </div>
  )
}

TGRFeatureBanner.propTypes = {
  backgroundImage: PropTypes.string,
  targetURL: PropTypes.string
}
