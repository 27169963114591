import React from 'react'
import * as styles from './details_pointer.module.scss'

import PropTypes from 'prop-types'

export default function TGRDetailsPointer ({ label, style }) {

  const styleCapitalized = style.charAt(0).toUpperCase() + style.slice(1)
  const constainerStyle = styles['container' + styleCapitalized]
  const lblStyle = styles['label' + styleCapitalized]

  return (
    <div className={constainerStyle}>
      <div className={lblStyle}>{label}
        <svg xmlns='http://www.w3.org/2000/svg' width='18px' height='24px'>
          <path fillRule='evenodd' d='M17.163,11.991 L4.279,24.001 L0.865,20.821 L10.314,12.013 L0.838,3.181 L4.252,0.000 L13.727,8.832 L13.749,8.811 L17.163,11.991 Z' />
        </svg>
      </div>
    </div>
  )
}

TGRDetailsPointer.propTypes = {
  label: PropTypes.string
}
