import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './menu_item_language.module.scss'

export default function MenuItemLanguage ({ className, langMap, item, styling, slugForLang  }) {
  const entry = langMap.get(item)
  const itemSlug = slugForLang(item)

  let content
  switch(styling){
    case 'image': {
        const img = getImage(entry.image);
        content = (<GatsbyImage
          layout="fullWidth"
          alt=""
          image={img}
          formats={["auto", "webp", "avif"]}
        />)
      }
      break
    default:
      content = entry.label
      break
  }

  const classList = className !== undefined ? [className, styles.item].join(' ') : styles.item

  return (
    <li className={classList}><Link to={itemSlug}>{content}</Link></li>
  )

}