import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function TGRNewsItemImage ({ item, stylingResolver, slugCreater }) {

  const hasImage = item.image !== null
  const img = hasImage ? getImage(item.image) : undefined

  return (
    <React.Fragment>
      {hasImage && <GatsbyImage
                layout="fullWidth"
                // This is a presentational image, so the alt should be an empty string
                alt=""
                image={img}
                formats={["auto", "webp", "avif"]}
              />
      }
    </React.Fragment>
  )
}


