import { useStaticQuery, graphql } from 'gatsby'

const usePageAddresses = () => {
  const { pageAddresses, newsPageAddresses } = useStaticQuery(graphql` 
  query SelectAllPages{ 
    pageAddresses: allPage { nodes { id language slug } }
    newsPageAddresses: allNewsPage { nodes { id language slug } } 
  }`)

  return pageAddresses.nodes.concat(newsPageAddresses.nodes)
}

export default usePageAddresses