exports.getConfigurationMap = (config) =>{
  const map = new Map();
  config.forEach( item => map.set(item.name, item.value))
  return map;
}

exports.getMenuMap = (config) =>{
  const map = {};
  config.forEach( curMenu => {
    map[curMenu.name] = {}

    curMenu.menus.forEach( curLang => {
      map[curMenu.name][curLang.language] = curLang.submenu
    })
  })
  return map;
}

exports.makeLangMap = (langList) =>{
  const langMap = new Map()
  langList.forEach(item => langMap.set(item.signature, item))
  return langMap
}

exports.makeSlug = ({defLang, language, langMap, slug}) => {
  const lp = language === defLang ? '' : `/${langMap.get(language).prefix}`
  return `${lp}/${slug === 'index' ? '' : slug}`
}

exports.createLangMenuList = ({languages, currentLanguage}) =>{
  const menuList = []

  languages.forEach( (val,sig) =>{
    if( sig !== currentLanguage)
      menuList.push(sig)
  })
  return menuList;
}

exports.filterSocialNodes = ({socialNodes, currentLanguage}) => {
  return socialNodes.filter( item => item.language === currentLanguage);
}