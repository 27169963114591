import React from 'react'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import LinkSwitch from './link_switch'
import MenuItemLanguage from './menu_item_language'

import logoImg from '../images/tgr-eu-logo-small.png'

import * as styles from './menu_panel.module.scss'
import useMenuMap from '../hooks/menu'

function MenuItem({ item, slugForPage }) {

  const title = item.title.toUpperCase()

  let itemContent = undefined

  switch (item.itemType) {
    case 'articleReference': itemContent = (<LinkSwitch isInternal={true} url={slugForPage(item.pageid)}>{title}</LinkSwitch>); break
    case 'urlRef': itemContent = (<LinkSwitch isInternal={false} url={item.urlRef} newWindow>{title}</LinkSwitch>); break
    case 'assetReference': itemContent = (<LinkSwitch isInternal={false} url={item.targetAsset.publicURL} newWindow>{title}</LinkSwitch>); break
    default: itemContent = (<React.Fragment>{title} UT: {item.typeHandle}</React.Fragment>)
  }

  return (
    <li className={'nav-item'}>{itemContent}</li>
  )

}

export default function TGRMenuPanel({ name,  dataName, lang, homePageSlug, langMenu, slugForPage, slugForLang, includeLogo }) {
  const menuMap = useMenuMap()
  const reqMenu = menuMap[dataName][lang]

  const hasMainMenu = reqMenu !== undefined
  const hasLangMenu = langMenu !== undefined && langMenu.langMenuList !== undefined && langMenu.langMenuList.length > 0

  return (
    <Navbar className={styles.navBox} collapseOnSelect expand='lg' bg='light' variant='light'>
      {includeLogo && <React.Fragment>
        <Navbar.Brand><LinkSwitch className='navbar-brand' isInternal={true} url={homePageSlug}><img src={logoImg} alt='TOYOTA GAZOO Racing' height={50} /></LinkSwitch></Navbar.Brand>
        <Nav className='me-auto'></Nav>
      </React.Fragment>}
      <Navbar.Toggle aria-controls={`navbar-${name}`} />
      {hasLangMenu && <React.Fragment>
        {langMenu.langMenuList.map((item, i) => (<MenuItemLanguage className={'d-block d-lg-none'} key={i} item={item} langMap={langMenu.langMap} styling={langMenu.styling} slugForLang={slugForLang} />))}
      </React.Fragment>}
      <Navbar.Collapse id={`navbar-${name}`}>
        {includeLogo && <Nav className='me-auto'></Nav>}
        <Nav className={styles.navbarNav}>
          {hasMainMenu && reqMenu.map((item, i) => (<Nav.Item key={i} bsPrefix='tgr_menu_item'><MenuItem item={item} slugForPage={slugForPage} /></Nav.Item>))}
        </Nav>
      </Navbar.Collapse>
      {hasLangMenu && <React.Fragment>
        <div className={[styles.split, 'd-none', 'd-lg-block'].join(' ')} />
        {langMenu.langMenuList.map((item, i) => (<MenuItemLanguage className={'d-none d-lg-block'} key={i} item={item} langMap={langMenu.langMap} styling={langMenu.styling} slugForLang={slugForLang} />))}
      </React.Fragment>}
    </Navbar>
  )
}
