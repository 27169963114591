import React from 'react'

import * as styles from './paragraph_title.module.scss'

export default function TGRParagraphTitle ({ text, style }) {

  const title = text !== null && text !== undefined && text.trim().length > 0 ? text.trim().toUpperCase() : undefined

  if(title === undefined)
    return null;

  return (
    <React.Fragment>
      <h1 className={styles.title + ' ' + styles[style]}>{title}</h1>
    </React.Fragment>)
}
