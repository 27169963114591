import React from 'react'

import * as styles from './news_page_item_content.module.scss'

export default function TGRNewsItemContent ({ item, stylingResolver, slugCreater }) {

  return (
    <React.Fragment>
      <p className={styles.content}>{item.content}</p>
    </React.Fragment>
  )
}