import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import * as styles from './gallery_viewer.module.scss'

export default function TGRGalleryViewer({ item, onCloseViewer }) {

  const [imgNumb, setImgNumb] = useState(0)
  const [listPageNumb, setListPageNumb] = useState(0)

  const imgListLen = 8
  const images = item.album.sort((a, b) => a.sortOrder > b.sortOrder)
  const pgCount = Math.trunc(images.length / imgListLen) + (images.length % imgListLen === 0 ? 0 : 1)

  const imgSchedule = images.slice(listPageNumb * imgListLen, Math.min(images.length, listPageNumb * imgListLen + imgListLen))

  const prevImgEnabled = imgNumb > 0
  const nextImgEnabled = imgNumb < images.length - 1
  const imgSchPrevEnabled = listPageNumb > 0
  const imgSchNextEnabled = listPageNumb < (pgCount - 1)

  function selectImageNumb(e, imgNumb) {
    setImgNumb(imgNumb)
    setListPageNumb(Math.trunc(imgNumb / imgListLen))
    e.stopPropagation()
  }

  function selectListPage(e, pgNumb) {
    setListPageNumb(Math.trunc(pgNumb))
    e.stopPropagation()
  }

  return (
    <React.Fragment>
      <div className={styles.bgOverlay}>
        <Grid container onClick={onCloseViewer}>
          <Grid item xs={3} container alignContent='center' justifyContent='flex-end'>
            <Grid item>
              {prevImgEnabled === true && <ChevronLeft className={styles.imgIcon} onClick={(e) => selectImageNumb(e, imgNumb - 1)} />}
            </Grid>
          </Grid>
          <Grid item xs={6}><GatsbyImage className={styles.gallImg} alt="" image={getImage(images[imgNumb].image)} /></Grid>
          <Grid item xs={3} container alignContent='center' justifyContent='flex-start'>
            <Grid item>
              {nextImgEnabled === true && <ChevronRight className={styles.imgIcon} onClick={(e) => selectImageNumb(e, imgNumb + 1)} />}
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center' spacing={3}>
            <Grid item xs={1} container alignContent='center' justifyContent='flex-end'>
              <Grid item>
                {imgSchPrevEnabled === true && <ChevronLeft className={styles.imgIcon} onClick={(e) => selectListPage(e, listPageNumb - 1)} />}
              </Grid>
            </Grid>
            {imgSchedule.map((img, i) => (
              <Grid item xs={1} key={`${i}-${img.thumbnail.childImageSharp.gatsbyImageData.images.fallback.src}`}
                onClick={(e) => selectImageNumb(e, listPageNumb * imgListLen + i)}>
                <GatsbyImage alt="" image={getImage(img.thumbnail)} />
              </Grid>
            ))}
            <Grid item xs={1} container alignContent='center' justifyContent='flex-start'>
              <Grid item>
                {imgSchNextEnabled === true && <ChevronRight className={styles.imgIcon} onClick={(e) => selectListPage(e, listPageNumb + 1)} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}