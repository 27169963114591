import React from 'react'

import * as styles from './news_page_item_title.module.scss'

export default function TGRNewsItemTitle ({ item }) {

  return (
    <React.Fragment>
      <h4 className={styles.title}>{item.title}</h4>
    </React.Fragment>
  )
}
