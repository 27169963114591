import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import SvgIcon from '@material-ui/core/SvgIcon'
import { Facebook, Help, Instagram, YouTube } from '@material-ui/icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'

import TGRParagraphTitle from './paragraph_title'
import LinkSwitch from './link_switch'

import * as styles from './social_grid.module.scss'

const useStyles = makeStyles({
  socialIcon: {
    height: '51px',
    width: '100%',
    marginBottom: '3px'
  },
  socialSvg: {
    height: '51px',
    width: '100%',
    marginBottom: '3px',
    padding: '4px'
  },
  socialGroup: {
    margin: '0px 15px',
    height: '90px'
  },
  socialGroupSplit: {
    borderLeft: 'solid red 1px'
  }
})

export default function TGRSocialGrid({ socialCollection, styling }) {

  const classes = useStyles()

  const TGRSocCellItem = ({ feed }) => {
    var img

    switch (feed.feedtype) {
      case 'facebook':
        img = <Facebook classes={{ root: classes.socialIcon }} />
        break;
      case 'instagram':
        img = <Instagram classes={{ root: classes.socialIcon }} />
        break;
      case 'tiktok':
        img = <SvgIcon classes={{ root: classes.socialSvg }} viewBox={'0 0 16 16'}>
          <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
        </SvgIcon>
        break;
      case 'twitter':
        img = <SvgIcon classes={{ root: classes.socialSvg }} viewBox={'0 0 1200 1227'} fill={'none'}>
          <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="black" />
        </SvgIcon>
        break;
      case 'youtube':
        img = <YouTube classes={{ root: classes.socialIcon }} />
        break;
      default:
        img = <Help classes={{ root: classes.socialIcon }} />
        break;
    }

    return (
      <React.Fragment>
        <LinkSwitch isInternal={false} url={feed.feedUrl} newWindow className={styles.socialCellItem}>
          <div>{img}</div>
        </LinkSwitch>
      </React.Fragment>
    )
  }

  if (socialCollection === undefined || socialCollection.length === 0)
    return null

  return (
    <React.Fragment>
      <Container className={styles.socialGrid}>
        <Row>
          <Col><TGRParagraphTitle text={'Social Media'} style={styling} /></Col>
        </Row>

        <Row className={['d-none', 'd-xl-block'].join(' ')}>
          <Stack direction='horizontal' className={'justify-content-center'}>

            {socialCollection.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((socEntry, i) =>
              <Col key={i} xs="auto" className={i !== 0 ? classes.socialGroupSplit : ''}>
                <div className={classes.socialGroup}>
                  <Container>
                    <Row><Col><p className={styling}>{socEntry.title}</p></Col></Row>
                    <Row>

                      {socEntry.feeds.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((feed, i) => <Col><TGRSocCellItem key={i} feed={feed} /></Col>)}

                    </Row>
                  </Container >
                </div>
              </Col>
            )}

          </Stack>
        </Row>

        <Row className={['d-block', 'd-xl-none'].join(' ')}>
          <Col>
            <Stack>

              {socialCollection.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((socEntry, i) =>

                <Container className={[styles.socialGrid, 'row-gap-3'].join(' ')}>
                  <Row key={i} className='justify-content-center'>
                    <Col>
                      <p className={styling}>{socEntry.title}</p>
                    </Col>
                  </Row>
                  <Row className='justify-content-center'>
                    {socEntry.feeds.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1).map((feed, i) => <Col key={i} xs='auto'><TGRSocCellItem feed={feed} /></Col>)}
                  </Row>
                </Container>
              )}

            </Stack >
          </Col>
        </Row>

      </Container>

    </React.Fragment >
  )
}
