import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './news_page_sponsors.module.scss'

export default function TGRNewsPageSponsors ({ sponsorList }) {

  function Sponsor({sponsor}){
    
    const name = sponsor.name
    const url = sponsor.url

    const img = sponsor.image !== null ? ( <GatsbyImage layout="fullWidth" alt={name} image={getImage(sponsor.image)} formats={["auto", "webp", "avif"]} />) : undefined

    let content = undefined
    if(img !== undefined){
      content = img
    } else if(name !== null) {
      content  = name
    }
      
    if(content !== null && sponsor.url !== null && sponsor.url.trim().length > 0) {
      content = <a href={url}>{img}</a>
    }

    return (
      <React.Fragment>
        <div className={styles.contentItem}>{content}</div>     
      </React.Fragment>
    )
  }

  if(sponsorList !== undefined && sponsorList.length > 0)  
    return (
      <React.Fragment>
          {sponsorList.sort( (a,b) => a.sortOrder > b.sortOrder).map( (item, index) => <Sponsor key={index} sponsor={item}/>)}
      </React.Fragment>
    )

  else
    return null
}
