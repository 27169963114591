import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import LinkSwitch from './link_switch'
import TGRImageDynamic from './image_dynamic'

import * as styles from './news_page_side_item_ext.module.scss'

export default function TGRNewsPageSideExtern({ item }) {

  let overlayImg = null;

  switch(item.overlay){
    case 'youTube': 
      overlayImg = <StaticImage src='../images/yt_logo_rgb_dark.png' alt="" />
      break;
    case 'youTubeAlt':
      overlayImg = <StaticImage src='../images/yt_logo_rgb_light.png' alt="" />
      break;
    default:
      overlayImg = null;
      break;
  }

  return (
    <React.Fragment>
      <LinkSwitch isInternal={false} url={item.url} newWindow>
        <div className={styles.containerWrapper}>
          <TGRImageDynamic item={item} />
          <div className={styles.overlay}>{overlayImg}</div>
        </div>
      </LinkSwitch>
    </React.Fragment>
  )
}
