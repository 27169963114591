import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import TGRGalleryViewer from './gallery_viewer'

import * as styles from './news_page_side_item_album.module.scss'

export default function TGRNewsPageSideAlbum ({ item }) {

  const [imageViewIsOpen, setImageViewIsOpen] = useState(false)

  const openImgViewer = () => setImageViewIsOpen(true)
  const closeImgViewer  = () => setImageViewIsOpen(false)
  
  return (
    <React.Fragment>
      <div className={styles.containerWrapper}>
        <GatsbyImage alt="" image={getImage(item.albumThumbNail)} onClick={openImgViewer}/>
      </div>
      {imageViewIsOpen === true && <TGRGalleryViewer item={item} onCloseViewer={closeImgViewer} />}
    </React.Fragment>
  )
}
