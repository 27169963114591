import React from 'react'

import * as styles from './paragraph_text_block.module.scss'

import PropTypes from 'prop-types'

export default function TGRParagraphTextBlock ({ text, style }) {

  const _content = text !== undefined && text !== null && text.trim().length > 0 ? text.trim() : undefined

  if(_content === undefined)
    return null;

  return (
    <React.Fragment>
      <p className={styles.text_body + ' ' + styles[style]}>{text}</p>
    </React.Fragment>)
}

TGRParagraphTextBlock.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string
}
