import React from 'react'

import * as styles from './paragraph_strap_title.module.scss'

import PropTypes from 'prop-types'

export default function TGRParagraphStrapTitle ({ text, style }) {

  const strapTitle = text !== undefined && text !== null && text.trim().length > 0 ? text.trim() : undefined
  if(strapTitle === undefined)
    return null;

  return (
    <React.Fragment>
      <h2 className={styles.title + ' ' + styles[style]}>{strapTitle}</h2>
    </React.Fragment>)
}

TGRParagraphStrapTitle.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string
}
