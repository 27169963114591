// extracted by mini-css-extract-plugin
export var aspectRatio = "paragraph_album-module--aspectRatio--4c9cb";
export var containerBlack = "paragraph_album-module--containerBlack--15766";
export var containerWhhite = "paragraph_album-module--containerWhhite--e6976";
export var containerWrapper = "paragraph_album-module--containerWrapper--d3475";
export var content = "paragraph_album-module--content--fa1d2";
export var item = "paragraph_album-module--item--39f70";
export var labelBlack = "paragraph_album-module--labelBlack--9798b";
export var labelWhite = "paragraph_album-module--labelWhite--1c667";
export var leadInOut = "paragraph_album-module--leadInOut--26b7e";
export var pointer = "paragraph_album-module--pointer--d74fd";