import React from 'react'

import TGRNewsPageSideExtern from './news_page_side_item_ext'

export default function TGRParagraphImageTile({ item, stylingResolver }) {

  return (
    <React.Fragment>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-3'} />
          <div className={'col-6'}>
            <TGRNewsPageSideExtern key={item.id} item={{ ...item, url: item.targetUrl }} stylingResolver={stylingResolver} />
          </div>
          <div className={'col-3'} />
        </div>
      </div>
    </React.Fragment>
  )
}