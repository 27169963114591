import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LinkSwitch from './link_switch'
import TGRParagraphTitle from './paragraph_title'
import TGRParagraphStrapTitle from './paragraph_strap_title'
import TGRParagraphTextBlock from './paragraph_text_block'
import TGRDetailsPointer from './details_pointer'

import * as styles from './paragraph.module.scss'

export default function TGRParagraph({ item, stylingResolver, slugCreater }) {

  const itemStyle = stylingResolver(item)

  function buildLink(lbl, winTarget, artId, assId, extUrl) {
    const link = {
      lbl: lbl !== undefined && lbl !== null && lbl.trim().length > 0 ? lbl.trim() : 'Details',
      winTarget: winTarget !== null && winTarget,
      internalSlug: artId !== undefined && artId !== null ? slugCreater(artId) : null,
      externalSlug: assId !== undefined && assId !== null
        ? assId.publicURL
        : (extUrl !== undefined ? extUrl : null)
    }
    link.isInternal = link.internalSlug !== null
    link.isValid = link.internalSlug !== null || link.externalSlug !== null

    return link;
  }

  const link1 = buildLink(item.targetarticle1label, item.targetInNewWindow1, item.targetarticle1Id, item.targetAsset1, item.targetUrl)
  const link2 = buildLink(item.targetarticle2label, item.targetInNewWindow2, item.targetarticle2Id, item.targetAsset2, item.targetUrl2)

  const disLink1 = link1.isValid ? link1 : link2
  const disLink2 = link1.isValid ? link2 : { isValid: false }

  const hasImage = item.sideImg !== null

  const paraImg = hasImage ? getImage(item.sideImg) : undefined
  const imgIsRight = item.imagePosition === 'right'

  const leadInStyling = item.prefixPadding !== null && item.prefixPadding === 'false' ? '' : ' ' + styles.leadIn

  const LinkBlock = ({ classes }) => {
    const clsList = classes ?? []
    return disLink1.isValid !== true ? null
      : (
        <Container fluid className={clsList.join(' ')}>
          <Row>
            <Col className={styles.firstLink}><LinkSwitch isInternal={disLink1.isInternal} url={disLink1.isInternal ? disLink1.internalSlug : disLink1.externalSlug} newWindow={disLink1.winTarget}><TGRDetailsPointer label={disLink1.lbl} style={itemStyle} /></LinkSwitch></Col>
            {disLink2.isValid === true && <Col><LinkSwitch isInternal={disLink2.isInternal} url={disLink2.isInternal ? disLink2.internalSlug : disLink2.externalSlug} newWindow={disLink2.winTarget}><TGRDetailsPointer label={disLink2.lbl} style={itemStyle} /></LinkSwitch></Col>}
          </Row>
        </Container>
      )
  }

  const ParaBlock = () => {
    return (
      <React.Fragment>
        <TGRParagraphTitle text={item.paragraphTitle} style={itemStyle} />
        <TGRParagraphStrapTitle text={item.strapTitle} style={itemStyle} />
        <TGRParagraphTextBlock text={item.text} style={itemStyle} />
      </React.Fragment>
    )
  }

  const ImgBlock = ({ classes }) => {
    const clsList = classes ?? []
    return paraImg === undefined ? null
    : ( <GatsbyImage className={clsList.join(' ')} layout="fullWidth" alt="" image={paraImg} />)
  }

  return (
    <Container>
      <Row className={leadInStyling}>

        {!hasImage && (<React.Fragment>
          <ParaBlock />
          <LinkBlock />
        </React.Fragment>
        )}

        {hasImage && <React.Fragment>
          <Col className='d-block d-md-none'>
            {paraImg !== undefined && (<ImgBlock classes={[styles.imgLeadOutSuf]} />)}
          </Col>

          {!imgIsRight &&
            <Col className='d-none d-md-block' md={6}>
              {paraImg !== undefined && (<ImgBlock />)}
              <LinkBlock classes={['d-none', 'd-md-block', 'd-lg-block', 'd-xl-none', styles.imgLeadOut]} />
            </Col>
          }
          <Col md={6}>
            <ParaBlock />
            <LinkBlock classes={['d-block', 'd-md-none', 'd-lg-none', 'd-xl-block']} />
          </Col>
          {imgIsRight &&
            <Col className='d-none d-md-block' md={6}>
              {paraImg !== undefined && (<ImgBlock />)}
              <LinkBlock classes={['d-block', 'd-xl-none', styles.imgLeadOut]} />
            </Col>
          }
        </React.Fragment>}
      </Row>
    </Container>
  )
}
