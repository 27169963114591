import React from 'react'
import { Link } from 'gatsby'

export default function LinkSwitch({ isInternal, url, newWindow, className, children }) {
  if (isInternal)
    return newWindow === undefined || !newWindow
      ? <Link className={className} to={url}>{children}</Link>
      : <Link className={className} to={url} target='_blank' rel='noreferrer'>{children}</Link>

  else
    return newWindow === undefined || !newWindow
      ? <a className={className} href={url}>{children}</a>
      : <a className={className} href={url} target='_blank' rel='noreferrer'>{children}</a>
}