// extracted by mini-css-extract-plugin
export var aspectRatioLarge = "feature_banner-module--aspectRatioLarge--4d3e5";
export var aspectRatioMedium = "feature_banner-module--aspectRatioMedium--62684";
export var aspectRatioSmall = "feature_banner-module--aspectRatioSmall--be57f";
export var black = "feature_banner-module--black--b267f";
export var blackEdge = "feature_banner-module--blackEdge--54fdb";
export var bottom = "feature_banner-module--bottom--05079";
export var containerWrapper = "feature_banner-module--containerWrapper--33584";
export var content = "feature_banner-module--content--792ee";
export var headerLine = "feature_banner-module--headerLine--2285d";
export var item = "feature_banner-module--item--6acd3";
export var sizeLarge = "feature_banner-module--sizeLarge--f4eb2";
export var sizeMedium = "feature_banner-module--sizeMedium--c4f67";
export var sizeSmall = "feature_banner-module--sizeSmall--f25d8";
export var slugLine = "feature_banner-module--slugLine--6138d";
export var top = "feature_banner-module--top--29057";
export var white = "feature_banner-module--white--72f80";
export var whiteEdge = "feature_banner-module--whiteEdge--ab3cc";