import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import TGRNewsItemTitle from './news_page_item_title'
import TGRNewsItemContent from './news_page_item_content'
import TGRNewsItemImage from './news_page_item_image'
import TGRNewsPageSponsors from './news_page_sponsors'
import TGRNewsPageSideExtern from './news_page_side_item_ext'
import TGRNewsPageSideAlbum from './news_page_side_item_album'

import * as styles from './news_page.module.scss'
import { Helmet } from 'react-helmet'

export default function TGRNewsPage ({ page, stylingResolver, slugCreater, location }) {

  const TGRNewsPageItem = ({item}) => {
    switch(item.typeHandle){
      case 'paragraphTitle':{
        return <TGRNewsItemTitle item={item} stylingResolver={stylingResolver} slugCreater={slugCreater}/>
      }
      case 'paragraphContent':{
        return <TGRNewsItemContent item={item} stylingResolver={stylingResolver} slugCreater={slugCreater}/>
      }
      case 'paragraphImage':{
        return <TGRNewsItemImage item={item} stylingResolver={stylingResolver} slugCreater={slugCreater}/>
      }
      default: return null;
    }
  }

  const TGRNewsPageSideItem = ({item}) => {
    switch(item.typeHandle){
      case 'sectionHeader':
        const style = styles.title + (item.horizontalRule === true ? ' ' + styles.secBreak : '')
        return <h2 className={style}>{item.text}</h2>

      case 'sponsor':
        return <TGRNewsPageSponsors sponsorList={item.sponsors} />

      case 'externalUrl':
        return <TGRNewsPageSideExtern item={item} />

      case 'album':
        return <TGRNewsPageSideAlbum item={item} />

      default: return null;
    }
  }

  const TGRNewsPageSideItemList = ({items}) => {
    const secs = []
    var secNumb = undefined
    items.sort( (a,b) => a.sortOrder > b.sortOrder).forEach(item => {
      if('sectionHeader' !== item.typeHandle || secNumb === undefined){
        if(secNumb === undefined){
          secNumb = 0
          secs[secNumb] = [item]

        } else {
          secs[secNumb].push(item)
        }

      } else {
        secs[++secNumb] = [item]
      }
    })

    return secs.map( (sec,i) => 
        <section key={'news-pg-sd-' + i}>
          {sec.map( (secEnt,j) => <TGRNewsPageSideItem key={'nwpg-sid-i-' + j} item={secEnt}/>)}
        </section>
      )
  }

  const title = page.title !== null && page.title.trim().length > 0 ? page.title : undefined
  const minor = page.minorTitle !== null && page.minorTitle.trim().length > 0 ? page.minorTitle : undefined
  const date = page.date !== null && page.date.trim().length > 0 ? page.date : undefined

  const twLink = 'https://twitter.com/intent/tweet?'
    + ( title !== undefined ? `text=${encodeURIComponent(title)}&` : '')
    + `url=${encodeURIComponent(location.href)}`

  const fbLink = `http://www.facebook.com/share.php?u=${encodeURIComponent(location.href)}`

  const sideItems = page.sideItems
  const hasSideItmes = sideItems !== undefined && sideItems !== null && sideItems.length > 0

  const mainColStyle = hasSideItmes ? 'col-9 ' : 'col-12 '

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:type"         content="article" />
        {title !== undefined && <meta property="og:title"        content={title}/>}
        {minor !== undefined && <meta property="og:description"  content={minor}/>}
      </Helmet>
      <div className={'container'}>
        <div className={'row'}>
          <div className={mainColStyle + 'container ' + styles.header}>
            {minor !== undefined && <div className={'row ' + styles.minorTitle}>{minor}</div>}
            {title !== undefined && <div className={'row ' + styles.title}>{title}</div>}
            <div className={'row justify-content-between ' + styles.dateLine}>
              <div className={'col-6'}>{date !== undefined && <div className={styles.date}>{date}</div>}</div>
              <div className={'col-6 ' + styles.socials}>
                <ul>
                  <li><a target='_blank' rel='noreferrer' href={fbLink} aria-label='Facebook link'><StaticImage src='../images/social-icon_facebook.svg' width={25} aspectRatio={1} alt=''/></a></li>
                  <li><a target='_blank' rel='noreferrer' href={twLink} aria-label='Twitter link'><StaticImage src='../images/social-icon_twitter.svg' width={25} aspectRatio={1} alt=''/></a></li>
                </ul>
              </div>
            </div>
            {page.items.sort( (a,b) => a.sortOrder > b.sortOrder).map((item, i) => (<div key={'nwpg-i-'+i} className={'row'}><TGRNewsPageItem item={item} /></div>))}
          </div>
          {hasSideItmes &&
            <React.Fragment>
              <div className={'col-2 offset-1'}>
                <TGRNewsPageSideItemList items={sideItems}/>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  )
}
