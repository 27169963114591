import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import * as styles from './paragraph_album.module.scss'

import TGRGalleryViewer from './gallery_viewer'

export default function TGRParagraphGallery({ item, stylingResolver, pageItemNumber }) {

  const [imageViewIsOpen, setImageViewIsOpen] = useState(false)

  const openImgViewer = () => setImageViewIsOpen(true)
  const closeImgViewer  = () => setImageViewIsOpen(false)

  const style = stylingResolver(item)
  const styleCapitalized = style.charAt(0).toUpperCase() + style.slice(1)
  const constainerStyle = styles.containerWrapper + ' ' + styles['container' + styleCapitalized]
  const labelStyle = styles[`label${styleCapitalized}`]

  const text = item.text !== null && item.text.trim().length > 0 ? item.text.toUpperCase().trim() : undefined

  return (
    <React.Fragment>
      <div className={'container ' + constainerStyle}>
        <div className={'row ' + styles.leadInOut}>
          <div className={'col-3'} />
          <div className={'col-6'}>
            <div className={[styles.containerWrapper, styles.pointer].join(' ')} onClick={openImgViewer} onKeyup={openImgViewer} role="button" aria-label="Open image gallery" tabindex={pageItemNumber}>
              <div className={styles.aspectRatio}></div>
              <div className={styles.content}>
                <GatsbyImage className={styles.item} layout="fullWidth" alt="" image={getImage(item.image)} />
                {text !== undefined && <div className={labelStyle}><h1>{text}</h1></div>}
              </div>
            </div>
          </div>
          <div className={'col-3'} />
        </div>
      </div>
      {imageViewIsOpen === true && <TGRGalleryViewer item={item} onCloseViewer={closeImgViewer} />}
    </React.Fragment>
  )
}