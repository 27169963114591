import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from "gatsby"

import useConfigMap from '../hooks/config'
import useLangMap from '../hooks/lang'
import usePageAddresses from '../hooks/pg_addresses'
import useSocials from '../hooks/socials'

import TGRMenuPanel from './menu_panel'
import TGRFeatureBanner from './feature_banner'
import TGRParagraph from './paragraph'
import TGRSocialGrid from './social_grid'
import TGRParagraphGallery from './paragraph_album'
import TGRParagraphImageTile from './paragraph_image_tile' 

import TGRNewsPage from './news_page'

import { makeSlug, createLangMenuList, filterSocialNodes } from '../services/utilities'

import * as styles from './tgr_site.module.scss'

export default function TGRPage({ data, location }) {

  function TGRPageItem({ pageItemNumber, item, stylingResolver, slugCreater }) {
    switch (item.typeHandle) {
      case 'titleBanner': return (<TGRFeatureBanner key={item.id} item={item} pageItemNumber={pageItemNumber} stylingResolver={stylingResolver} slugCreater={slugCreater} />)
      case 'paragraph': return (<TGRParagraph key={item.id} item={item} pageItemNumber={pageItemNumber} stylingResolver={stylingResolver} slugCreater={slugCreater} />)
      case 'album': return (<TGRParagraphGallery key={item.id} item={item} stylingResolver={stylingResolver} pageItemNumber={pageItemNumber} />)
      case 'externalUrl': return (<TGRParagraphImageTile key={item.id} item={{...item,url: item.targetUrl}} stylingResolver={stylingResolver} />)

      default:
        return (<div>Unknown item : {'-' + item.typeHandle + '-'}</div>)
    }
  }

  const blankParagraph = { itemStyling: 'asDefinedByPage', prefixPadding: 'true' }

  const configMap = useConfigMap()
  const langMap = useLangMap()
  const allPages = usePageAddresses()
  const socials = useSocials()

  const defLang = configMap.get('defLanguage')

  const srcPg = data.page != null ? data.page : data.newsPage
  const pageStyling = data.page !== null ? srcPg['itemStyling'] : 'black'

  const makeSlugForPage = (pgId) => {
    const targetPg = allPages.filter(item => item.id === pgId)[0]
    return makeSlug({ defLang, language: targetPg.language, langMap, slug: targetPg.slug })
  }
  const makeSlugForLang = (lang) => {
    const targetPg = srcPg.translations.filter(item => item.language === lang)[0]
    return makeSlugForPage(targetPg.id)
  }
  const resolveItemStyling = (item) => { return item.itemStyling === 'asDefinedByPage' || item.itemStyling === null ? pageStyling : item.itemStyling }

  const languageHomePg = makeSlug({ defLang, language: srcPg.language, langMap, slug: 'index' })

  const socialCollection = filterSocialNodes({ socialNodes: socials, currentLanguage: srcPg.language })

  const langMenu = {
    langMap: langMap,
    langMenuList: createLangMenuList({ languages: langMap, currentLanguage: srcPg.language }),
    styling: configMap.get('languageMenuType')
  }

  const title = data.page !== null ? data.page.title : data.newsPage.title;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="google-site-verification" content="5PGw9AEdot9ZWr6280USpETPG57tCv5FumArblma2cU" />
        <meta property={'og:url'} content={location.href} />
        <meta property={'og:title'} content={title} />
        <meta property={'og:site_name'} content={'TOYOTA GAZOO Racing'} />
      </Helmet>
      <div>
        <main>
          <TGRMenuPanel name='main_menu' dataName='topMenu' lang={srcPg.language} homePageSlug={languageHomePg} langMenu={langMenu} slugForPage={makeSlugForPage} slugForLang={makeSlugForLang} includeLogo={true} />

          {data.page !== null && data.page.items.sort((a, b) => a.sortOrder > b.sortOrder).map((item, index) => (<div key={index} className={styles.pageItem + ' ' + styles[resolveItemStyling(item)]}><TGRPageItem pageItemNumber={index} stylingResolver={resolveItemStyling} item={item} slugCreater={makeSlugForPage} /></div>))}
          {data.newsPage !== null && <TGRNewsPage location={location} page={data.newsPage} stylingResolver={resolveItemStyling} slugCreater={makeSlugForPage} />}
          <div className={[styles.pageItem,styles[resolveItemStyling(blankParagraph)]].join(' ')}>
            <TGRParagraph item={blankParagraph} stylingResolver={resolveItemStyling} />
          </div>
        </main>

        <footer>
          <TGRSocialGrid socialCollection={socialCollection} styling={pageStyling} />

          <TGRMenuPanel name='foot_menu' dataName='footerMenu' lang={srcPg.language} slugForPage={makeSlugForPage} />
        </footer>

      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  fragment assetSpec on File { size publicURL prettySize }
  fragment sponsorImgSpec on File { childImageSharp { gatsbyImageData(width: 202, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment albumImgThumbSpec on File { childImageSharp { gatsbyImageData(width: 160, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment albumThumbNailSpec on File { childImageSharp { gatsbyImageData(width: 202, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment albumImgSpec on File { childImageSharp { gatsbyImageData(width: 900, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment newsSideImgSpec on File { childImageSharp { gatsbyImageData(width: 780, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment newsImgSpec on File { childImageSharp { gatsbyImageData(width: 780, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment bgImgSpec on File { childImageSharp { gatsbyImageData(width: 1600, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment sideImgSpec on File { childImageSharp { gatsbyImageData(width: 650, layout: CONSTRAINED, formats:[AUTO]) } }
  fragment postImgSpec on File { childImageSharp { gatsbyImageData(width: 900, layout: CONSTRAINED, formats:[AUTO]) } }

  query SelectPost($id: String) {
    page(id: {eq: $id}) { id slug language itemStyling
      title translations { language id }
      items { sortOrder itemStyling imagePosition typeHandle text paragraphTitle strapTitle targetPageId headline headlinePosition headlineFontSize headlineColour headlineEdging readMeLabel overlay prefixPadding bgImageHeight
        bgImg { ...bgImgSpec } 
        sideImg { ...sideImgSpec }
        image { ...postImgSpec }        
        album { image { ...newsImgSpec } thumbnail:image {...albumImgThumbSpec } }
        targetUrl targetUrl2
        targetarticle1label targetarticle1Id targetInNewWindow1 targetAsset1 {...assetSpec}
        targetarticle2label targetarticle2Id targetInNewWindow2 targetAsset2 {...assetSpec}
      }
    }
    newsPage(id: {eq: $id}) { id slug language itemStyling
      title minorTitle date
      translations { language id }
      items { sortOrder itemStyling typeHandle title content horizontalRule
        image { ...newsImgSpec }
      }
      sideItems { typeHandle sortOrder overlay text horizontalRule url
        album { sortOrder image {...albumImgSpec } thumbnail:image {...albumImgThumbSpec } }
        albumThumbNail:image { ...albumThumbNailSpec }
        image { ...newsSideImgSpec }
        sponsors { sortOrder name enabled url image { ...sponsorImgSpec }}
      }
    }
  }`

